<template>
  <section class="box">
    <AppLineLoading v-if="isLoading" />
    <div class="page-header xa-cell">
      <el-input
        class="xa-mgr-16"
        v-on:keyup.enter.native="onSearchQuery()"
        placeholder="请输入完整手机号,可通过空格隔开多个输入"
        type="phone"
        v-model="keyword"
        size="small"
      >
        <i slot="prefix" class="el-input__icon el-icon-search"></i>
      </el-input>
      <el-button
        type="primary"
        size="small"
        @click="onSearchQuery"
        :disabled="keyword.trim().length < minPhoneLen"
        icon="el-icon-search"
      ></el-button>
    </div>
    <div class="page-content">
      <div v-if="msg || users.length == 0">
        <div
          style="text-align:center;padding:4px;"
          class="xa-txt-secondary"
          v-html="msg || '需要输入完善的用户手机号来进行搜索'"
        ></div>
      </div>
      <div>
        <el-tag
          style="margin:4px"
          v-for="tag in users"
          :key="tag.title"
          closable
          :disable-transitions="false"
          @close="handleClose(tag)"
        >
          {{ tag.title }}
        </el-tag>
      </div>
    </div>
    <div class="page-foot xa-cell">
      <el-button size="small" @click="$emit('close')">取消</el-button>
      <el-button
        :disabled="users.length === 0"
        type="primary"
        size="small"
        @click="onSubmit"
        >确定</el-button
      >
    </div>
  </section>
</template>
<script>
// 电话号码最小长度定为8，因为有国外用户
import { searchUser } from '@target/apis/coupon'
export default {
  data() {
    return {
      minPhoneLen: 8,
      isLoading: false,
      keyword: '',
      users: [],
      msg: ''
    }
  },
  methods: {
    handleClose(user) {
      this.users.splice(this.users.indexOf(user), 1)
    },
    onSubmit() {
      this.keyword = ''
      this.$emit('submit', this.users)
      this.users = []
    },
    analysisUser(users, phone) {
      if (users && users.length) {
        const [user] = users
        const { title, value } = user
        return {
          title,
          phone,
          name: title.match(/[^/(]+/)[0],
          guid: value
        }
      }
    },
    async onSearchQuery() {
      this.isLoading = true
      try {
        if (this.keyword.trim().length < this.minPhoneLen) return
        this.msg = ''
        const phones = this.keyword
          .trim()
          .split(/[\s]+/g)
          .filter(item => item.length >= this.minPhoneLen)
        if (phones.length === 0) return
        const results = await Promise.all(
          phones.map(item =>
            searchUser({
              keyword: item
            })
          )
        )
        const users = results
          .map(({ options }, index) =>
            this.analysisUser(options, phones[index])
          )
          .filter((item, index) => {
            if (!item) this.msg += phones[index] + ':没有对应的用户' + '<br>'
            return !!item
          })
        if (users.length) {
          for (let i = 0; i < users.length; i++) {
            if (
              this.users.findIndex(has => has.phone === users[i].phone) === -1
            ) {
              this.users.push(users[i])
            }
          }
        }
      } catch (error) {
        this.msg = error
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.box {
  position: relative;
  margin: 0 auto;
}
.page-header {
  padding-top: 8px;
}
.page-content {
  padding: 16px 0;
  min-height: 48px;
}
.page-foot {
  padding-bottom: 16px;
  justify-content: flex-end;
}
</style>
